<template>
  <div class="feedback__container">
    <div class="feedback__inner">
    <h2>Feedback</h2>
      <form method="post" @submit.prevent="postForm">
        <div class="form__textbox">
          <label for="name">Namn</label>
          <input type="text" name="name" v-model="formName" />
        </div>
        <div class="form__textbox">
          <label for="email">Mail</label>
          <input type="email" name="email" v-model="formEmail" />
        </div>
        <div class="form__textarea">
          <label for="body">Meddelande</label>
          <textarea type="text" name="body" cols="40" rows="15" v-model="formBody" />
        </div>
        <input type="submit" ref="feedbackButton" value="Skicka" class="btn feedbackbutton" :class="{feedbacksuccess : feedbackSent}">
      </form>
      <p>Vänligen anmäl biverkningar till <a href="https://www.sandoz.se/om-oss/kontakta-oss/biverkningsrapportering">vårt lokala verktyg genom att klicka här</a></p>
      <div class="form-sent" :class="{ success : feedbackSent }">Din feedback är skickad, tack för din hjälp!</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Feedback",
  data: function () {
    return {
      formName: '',
      formEmail: '',
      formBody: '',
      feedbackSent: false
    };
  },
  methods: {
    postForm: function() {
      const formData = new FormData()
      formData.append('Namn', this.formName)
      formData.append('Mailadress', this.formEmail)
      formData.append('Feedback', this.formBody)
      axios.post('mailform.php', formData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }).then((res) => {
        if (res.status === 200) {
          this.feedbackSent = true;
          this.$refs.feedbackButton.value = "Skickad"
        }
        console.log(res)
      })
    },
  }
}
</script>

<style lang="scss">

.feedback__container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  background-color: rgba(255,255,255,0.8);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.feedback__inner {
  position: relative;
  box-sizing: border-box;
  margin: 2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 5px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  div.closebutton {
    position: absolute;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
  }
  h2 {
    text-align: left;
  }
  input[type=text], input[type=email], textarea  {
    width: 100%;
    padding: .5rem;
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 5px;
  }
  form {
    display: flex;
    flex-flow: row wrap;
  }
  label {
    font-size: .8rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #777;
    margin-bottom: .5rem;
  }
  .form__textbox {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 2rem;
    &:nth-of-type(2) {
      padding-right: 0;
    }
  }
  .form__textarea {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  .btn.feedbacksuccess {
    background-color: #19bb42;
    border-color: #158b32;
    &:hover {
      background-color: #19bb42;
      border-color: #158b32;
    }
  }
  .form-sent {
    opacity: 0;
    &.success {
      transition: opacity 500ms ease-in;
      opacity: 1;
    }
  }
}
</style>