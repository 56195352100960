<template>
  <div class="contact__container">
    <h1>Kontakt</h1>
    <div class="columns">
      <div class="col">
        <img
          :src="presenter.avatar"
          width="320px"
          :alt="'Avatar for ' + presenter.firstName"
        />
        <h2>{{ presenter.firstName }} {{ presenter.lastName }}</h2>
        <p><strong>Mobil: </strong> {{ presenter.phone }}</p>
        <p>
          <strong>Mail: </strong>
          <a :href="'mailto:' + presenter.eMail">{{ presenter.eMail }}</a>
        </p>
      </div>
      <div class="col">
        <p>För att få mer info digitalt, scanna QR-koden</p>
        <img
          src="../assets/qr-code.png"
          class="qr"
          width="240px"
          alt="QR-kod"
        />
        <p>
          För mer information om TAF/MEK, besök
          <a href="http://www.medhub.se" target="_blank">www.medhub.se</a>
        </p>
        <div>
          <a class="buttonlink" href="../feedback" target="_blank"><div class="btn">Lämna feedback</div></a>
          <a class="buttonlink" :href="mailtoLink"><div class="btn">Boka nytt möte</div></a>
          <div v-if="browsingActive" class="btn" @click="playSlides">Visa presentationen</div>
        </div>
      </div>
    </div>
    <!-- <transition name="fade">
      <div v-if="showFeedback" class="feedback__container">
        <div class="feedback__inner">
        <h2>Feedback</h2>
        <div class="closebutton" @click="showFeedback = false">&#10005;</div>
          <form method="post" @submit.prevent="postForm">
            <div class="form__textbox">
              <label for="name">Namn</label>
              <input type="text" name="name" v-model="formName" />
            </div>
            <div class="form__textbox">
              <label for="email">Mail</label>
              <input type="email" name="email" v-model="formEmail" />
            </div>
            <div class="form__textarea">
              <label for="body">Meddelande</label>
              <textarea type="text" name="body" cols="40" rows="15" v-model="formBody" />
            </div>
            <input type="submit" ref="feedbackButton" value="Skicka" class="btn feedbackbutton" :class="{feedbacksuccess : feedbackSent}">
          </form>
          <p>Vänligen anmäl biverkningar till <a href="https://www.sandoz.se/om-oss/kontakta-oss/biverkningsrapportering">vårt lokala verktyg genom att klicka här</a></p>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      // showFeedback: false,
      // formName: '',
      // formEmail: '',
      // formBody: '',
      // feedbackSent: false
    }
  },
  computed: {
    presenter() {
      return this.$store.state.presenter;
    },
    browsingActive() {
      return this.$store.state.browsingActive;
    },
    endScreenActive() {
      return this.$store.state.endScreenActive;
    },
    mailtoLink() {
      return 'mailto:' + this.presenter.eMail + '?subject=Boka nytt möte';
    }
  },
  methods: {
    playSlides: function () {
      const payload = {'chapter': 0, 'slide': 0}
      this.$store.dispatch("GOTOSLIDE", payload);
      this.$router.push({
        name: "Viewslide",
        params: { id: this.$store.state.presentationId },
      });
    },
    openFeedback: function () {
      this.showFeedback = true;
    },
    postForm: function() {
      const formData = new FormData()
      formData.append('Namn', this.formName)
      formData.append('Mailadress', this.formEmail)
      formData.append('Feedback', this.formBody)
      axios.post('mailform.php', formData, {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      }).then((res) => {
        if (res.status === 200) {
          this.closeFeedback()
        }
        console.log(res)
      })
    },
    closeFeedback: function () {
      this.feedbackSent = true
      this.$refs.feedbackButton.value = 'Skickat'
      setTimeout(() => {
          this.feedbackSent = false
          this.$refs.feedbackButton.value = 'Skicka'
          this.showFeedback = false
        }, 4000);
    }
  },
  watch: {
    browsingActive(newBrowsing) {
      if (newBrowsing == false) {
        this.$router.push({
          name: "Viewslide",
          params: { id: this.$store.state.presentationId },
        });
      }
    },
    endScreenActive(newBrowsing) {
      if (newBrowsing == false) {
        this.$router.push({
          name: "Viewslide",
          params: { id: this.$store.state.presentationId },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.contact__container {
  // flex: 1 1 auto;
  // width: 80%;
  // max-width: 64rem;
  width: 40rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navbarheight) - var(--footerheight));
  overflow-y: auto;
  h1 {
    text-align: left;
    margin: 1rem;
    border-bottom: var(--border);
  }
  .columns {
    display: flex;
    flex-direction: row;
  }
  .col {
    margin: 1rem;
    flex: 0 1 20rem;
    text-align: left;
    h2 {
      border-bottom: var(--border);
      display: inline-block;
      margin-bottom: 0;
    }
    .btn {
      text-align: center;
      margin-bottom: 1rem;
      width: 240px;
    }
    img {
      display: block;
      width: 240px;
    }
    img.qr {
      width: 120px;
    }
  }
  a.buttonlink {
    text-decoration: none;
  }
}
// .feedback__container {
//   position: absolute;
//   background-color: rgba(255,255,255,0.8);
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }
// .feedback__inner {
//   position: relative;
//   box-sizing: border-box;
//   margin: 2rem;
//   padding: 2rem;
//   background-color: #fff;
//   border-radius: 5px;
//   max-width: 800px;
//   width: 100%;
//   box-shadow: 0 0 20px rgba(0,0,0,0.3);
//   div.closebutton {
//     position: absolute;
//     height: 2rem;
//     width: 2rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-weight: 700;
//     cursor: pointer;
//     top: 1rem;
//     right: 1rem;
//   }
//   h2 {
//     text-align: left;
//   }
//   input[type=text], input[type=email], textarea  {
//     width: 100%;
//     padding: .5rem;
//     box-sizing: border-box;
//     border: 2px solid #ddd;
//     border-radius: 5px;
//   }
//   form {
//     display: flex;
//     flex-flow: row wrap;
//   }
//   label {
//     font-size: .8rem;
//     text-transform: uppercase;
//     font-weight: bold;
//     color: #777;
//     margin-bottom: .5rem;
//   }
//   .form__textbox {
//     flex: 1 1 50%;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     padding-right: 2rem;
//     &:nth-of-type(2) {
//       padding-right: 0;
//     }
//   }
//   .form__textarea {
//     margin-top: 1rem;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     align-items: flex-start;
//     margin-bottom: 2rem;
//   }
//   .btn.feedbacksuccess {
//     background-color: #19bb42;
//     border-color: #158b32;
//     &:hover {
//       background-color: #19bb42;
//       border-color: #158b32;
//     }
//   }
// }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>